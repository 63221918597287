@import 'assets/css/constants.scss';

.search-bar {
  width: 100%;
  height: 47px;
  max-width: 707px;
  background-color: $color-secondary;
  border-radius: 6px;
  display: flex;
  align-items: center;
  overflow: hidden;
  transition: all .2s ease-out;
  pointer-events: all;
  padding: 0 12px;
  outline: 2px solid transparent;

  img {
    width: 24px;
    height: 24px;
    transition: all .2s ease-out;
  }

  input {
    all: unset;
    width: 100%;
    font-size: 16px;
    opacity: 1;
    padding-right: 20px;
    padding-left: 10px;
    transition: all .2s ease-out;

    &::placeholder {
      color: #969696;
    }
  }

  &:hover {
    outline: 2px solid #9271EE;
  }
}