@import 'assets/css/constants.scss';

.config {
  max-width: 380px;
  width: 100%;
  height: calc(100vh - $topbar-height - $project-header-height);
  height: calc(100svh - $topbar-height - $project-header-height);
  background-color: white;
  padding: 31px 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow-y: auto;

  div.map-type {
    width: 100%;
    height: 150px;
    border-radius: 10px;
    border: 1px solid rgb(244, 244, 244);
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    position: relative;
    cursor: pointer;

    span {
      display: inline-flex;
      color: $color-main;
      font-size: 16px;
      line-height: 16px;
      font-weight: 400;
      padding: 10px 16px;
      background: $color-secondary;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      transition: all 0.15s ease-in-out;
    }

    svg {
      opacity: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      transition: all 0.15s ease-in-out;
    }

    .shadow {
      opacity: 0;
      background-color: rgba(0, 0, 0, 0.3);
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      z-index: 0;
      transition: all 0.15s ease-in-out;
    }

    &.selected {
      span {
        background: $color-main;
        color: white;
      }

      svg, .shadow {
        opacity: 1;
      }
    }
  }
}