@import 'assets/css/constants.scss';

#handle {
  width: 10px;
  height: calc(100vh - $topbar-height - $project-header-height);
  height: calc(100svh - $topbar-height - $project-header-height);
  background: #1e1e1e;
  cursor: col-resize;
  gap: 1px;

  > div {
    pointer-events: none;
    width: 2px;
    height: calc(100vh - $topbar-height - $project-header-height);
    height: calc(100svh - $topbar-height - $project-header-height);
    background: #424242;
  }
}