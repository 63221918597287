@import 'assets/css/constants.scss';

header {
  background-color: #2F2F2F;
  height: $topbar-height;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 990;
  padding: 0 24px 0 0;
  display: flex;
  align-items: center;

  *, .MuiButtonBase-root {
    color: white;
  }

  .MuiButtonBase-root {
    font-weight: 800;
  }

  .logos {
    display: flex;
    gap: 28px;
  }

  > p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 250px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    transition: all 0.15s ease-in-out;
  }

  // target div AccountMenu wrapper
  div:last-of-type {
    margin-left: auto;
  }

  &.access-denied {
    padding: 0 24px;
  }
}

@media screen and (max-width: 950px) {
  header .logos > img {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  header {
    > p {
      text-align: left;
      left: 115px;
      transform: translate(0%, -50%);
    }

    .logos {
      display: none;
    }
  }
}

@media screen and (max-width: 600px) {
  header {
    > p {
      width: 150px;
      font-size: 14px;
    }
  }
}

@media screen and (max-width: 450px) {
  header {
    > p {
      opacity: 0;
    }
  }
}