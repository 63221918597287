@import 'assets/css/constants.scss';

.layers {
  max-width: 380px;
  width: 100%;
  height: calc(100vh - $topbar-height - $project-header-height);
  height: calc(100svh - $topbar-height - $project-header-height);
  background-color: white;
  padding: 31px 24px;
  display: flex;
  flex-direction: column;
  gap: 25px;
  overflow-y: auto;

  .checkbox-container {
    display: flex;
    align-items: center;

    input {
      display: none;
    }

    input + label {
      position: relative;
      font-size: 14px;
      font-weight: 400;
      text-transform: capitalize;
      cursor: pointer;
      display: inline-flex;
      align-items: center;
      height: 25px;
      color: rgb(22, 22, 22);

      > div {
        display: flex;
        flex-direction: column;

        > span {
          &:first-of-type {
            font-size: 12px;
            color: rgb(105, 28, 202);
            font-weight: 400;
          }
        }
      }

      &:hover {
        > div > span:last-of-type {
          text-decoration: underline;
        }
      }
    }
  
    input + label::before {
      content: " ";
      margin-right: 10px;
      width: 23px;
      height: 23px;
      background-color: white;
      border: 1px solid rgb(217, 217, 217);
      border-radius: 6px;
    }
  
    input:checked + label::after {
      content: " ";
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48cGF0aCBmaWxsPSIjNmExY2NhIiBkPSJNMTczLjg5OCA0MzkuNDA0bC0xNjYuNC0xNjYuNGMtOS45OTctOS45OTctOS45OTctMjYuMjA2IDAtMzYuMjA0bDM2LjIwMy0zNi4yMDRjOS45OTctOS45OTggMjYuMjA3LTkuOTk4IDM2LjIwNCAwTDE5MiAzMTIuNjkgNDMyLjA5NSA3Mi41OTZjOS45OTctOS45OTcgMjYuMjA3LTkuOTk3IDM2LjIwNCAwbDM2LjIwMyAzNi4yMDRjOS45OTcgOS45OTcgOS45OTcgMjYuMjA2IDAgMzYuMjA0bC0yOTQuNCAyOTQuNDAxYy05Ljk5OCA5Ljk5Ny0yNi4yMDcgOS45OTctMzYuMjA0LS4wMDF6Ii8+PC9zdmc+");
      background-repeat: no-repeat;
      background-size: 15px 15px;
      background-position: center center;
      position: absolute;
      left: 0px;
      top: 0px;
      font-size: 10px;
      height: 25px;
      width: 25px;
    }
  }
}