.filters-modal {
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }  

  div.filters, div.actions, div.filters > div {
    gap: 16px;
  }

  div.filter {
    width: 100%;
  
    > div.select:nth-child(2)  {
      width: 200px;
    }

    > div:nth-child(3) {
      width: 200px;
    }

    button {
      font-size: 20px;
      padding: 0;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      min-width: unset;
      align-self: flex-end;
      margin-bottom: 3px;
    }
  }

  @media screen and (max-width: 768px) {
    div.filter {
      display: grid;
      gap: 10px !important;
      grid-template:
        "b a a d"
        "c c c c";

      > div.select:nth-child(1) {
        grid-area: b;
      }

      > div.select:nth-child(2)  {
        grid-area: a;
      }

      > div:nth-child(3)   {
        grid-area: c;
        width: 100%;

        label {
          display: none;
        }
      }

      > button {
        grid-area: d;
      }
    }
  }

  @media screen and (max-width: 640px) {
    div.filter {
      display: grid;
      gap: 12px;
      grid-template:
        "b b b d"
        "a a a a"
        "c c c c";

      > div.select:nth-child(2) {
        width: 100%;

        label {
          display: none;
        }
      }
    }
  }
}
