@import 'assets/css/constants.scss';

#project {
  padding-top: $project-header-height;
  display: flex;
  overflow: hidden;

  &.no-padding {
    padding-top: 0;
  }
}
