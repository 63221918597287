@import 'assets/css/constants.scss';

.share-modal {
  div.head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;

    a {
      gap: 10px;
      color: $color-main;
      font-size: 16px;
      font-weight: 800;
      line-height: 24px;
      cursor: pointer;

      &:hover {
        text-decoration-line: underline;
      }
    }
  }

  .share-search-bar {
    position: relative;
    width: 100%;
    height: 47px;
    max-width: 707px;
    background-color: $color-secondary;
    border-radius: 6px;
    display: flex;
    align-items: center;
    overflow: unset;
    transition: all .2s ease-out;
    pointer-events: all;
    padding: 0 0 0 12px;
  
    input {
      all: unset;
      width: 100%;
      font-size: 16px;
      opacity: 1;
      padding-left: 10px;
      transition: all .2s ease-out;
  
      &::placeholder {
        color: #969696;
      }
    }
  
    .simple-dropdown {
      flex-shrink: 0;
      min-width: 95px;
      margin-right: 10px;
      margin-left: 10px;
    }
  
    .simple-button {
      height: 47px;
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }
  
    .results {
      position: absolute;
      top: 47px;
      left: 0;
      width: calc(100% - 64px);
      background-color: $color-secondary;
      border-radius: 0 0 6px 6px;
      z-index: 999;
      max-height: 280px;
      overflow-y: auto;
      box-shadow: 0px 17px 25px rgba(0, 0, 0, 0.15);
  
      &::-webkit-scrollbar {
        display: none;
      }
  
      div {
        padding: 10px 14px;
        cursor: pointer;
        transition: all .1s ease-out;
  
        p {
          font-size: 16px;
          line-height: 24px;
  
          &:first-of-type {
            font-weight: 800;
          }
  
          &:last-of-type {
            font-weight: 400;
          }
        }
  
        &:hover {
          background-color: #4E4A5B;
          color: white;
        }
  
        &:last-of-type {
          border-bottom-left-radius: 6px;
          border-bottom-right-radius: 6px;
        }
      }
    }
  
    &:hover {
      outline: 2px solid #9271EE;
    }
  
    &.has-results {
      border-bottom-left-radius: 0;
  
      &:hover {
        outline: none;
      }
    }
  }
}