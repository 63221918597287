.input-wrapper.color-picker-wrapper {
  .inputs {
    display: flex;
    align-items: center;
    height: 50px;
    gap: 12px;
    padding-right: 10px;
  
    > input[type="text"] {
      width: 100%;
      text-align: left;
      pointer-events: none;
    }
  
    .picker {
      display: flex;
      width: 35px;
      height: 35px;
      flex-shrink: 0;
      align-items: center;
      position: relative;
  
      > input, .preview {
        position: absolute;
        top: 0;
        left: 0;
        width: 35px;
        height: 35px;
      }
    
      > input {
        opacity: 0;
      }
    
      > .preview {
        border-radius: 3px;
        cursor: pointer;
  
        &:hover {
          transform: scale(1.04);
        }
      }
    }
  }
}
