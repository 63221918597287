@import 'assets/css/constants.scss';

.card-project {
  position: relative;
  padding: 24px;
  width: 300px;
  min-height: 268px;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  border-radius: 8px;
  border: 1px solid $color-secondary;
  background: #FFF;

  > h2 {
    color: #030E18;
    font-size: 20px;
    font-weight: 800;
    line-height: 20px;
    max-width: 216px;
    height: 21px;
    letter-spacing: 0.2px;
    margin-bottom: 7px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  > h3 {
    color: $color-main;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    min-height: 21px;
    margin-bottom: 15px;
    border-radius: 14.5px;
    background-color: rgba(103, 28, 202, 0.10);
    align-self: flex-start;
    padding: 0 11px;
  }

  > p {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: #4B4B4B;
    font-size: 14px;
    min-height: 63px;
    font-weight: 400;
    line-height: 21px;
    margin-bottom: 5px;
  }

  > span {
    color: #B7B7B7;
    font-family: Avenir;
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 24px;
  }

  > .simple-dropdown {
    position: absolute;
    top: 22px;
    right: 24px;
  }

  &.pending {
    user-select: none;
    pointer-events: none;

    * {
      background: #eee !important;
      color: transparent !important;
      border-radius: 4px !important;
      background-size: 200% 100% !important;
      animation: 1.5s shine linear infinite;
    }

    .simple-dropdown {
      display: none;
    }
  }
}

@keyframes shine {
  0% {
    background: #c4c3c3 !important;
  }
  50% {
    opacity: 0.5;
    background: #eee !important;
  }
  100% {
    background: #c4c3c3 !important;
  }
}