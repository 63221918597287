@import 'assets/css/constants.scss';

.project-header {
  position: fixed;
  top: $topbar-height;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: $project-header-height;
  background: $color-secondary;

  .icon-action {
    width: $project-header-height;
    height: 56px;
    cursor: pointer;
    overflow: hidden;
    transition: width 0.15s linear;

    svg {
      width: 24px;
    }
  
    &.active, &:hover {
      background-color: $color-main;
      
      svg * {
        fill: white;
      }
    }

    &.hidden {
      width: 0;
    }
  }

  > button {
    height: 40px;
    margin-left: 5px;
  }

  .loader {
    margin-left: auto;
    margin-right: 15px;
  }
}