.accordion {
  width: 100%;

  h3 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    user-select: none;
    cursor: pointer;
    margin-bottom: 0;
    transition: all 0.15s ease-in-out;

    svg {
      transition: all 0.15s ease-out;
    }

    &:hover span {
      text-decoration-line: underline;
    }
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    transition: none;
    overflow: hidden;

    > * {
      flex-shrink: 0;
    }
  }

  &.expanded {
    h3 {
      margin-bottom: 24px;

      svg {
        transform: rotate(180deg);
      }  
    }
  }

  &.enable-transition .wrapper {
    transition: all 0.2s ease-in-out;
  }
}