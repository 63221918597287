@import 'assets/css/constants.scss';

.map-gl {
  position: relative;
  width: 100%;
  height: calc(100vh - $topbar-height - $project-header-height);
  height: calc(100svh - $topbar-height - $project-header-height);

  &.no-project-header {
    height: calc(100vh - $topbar-height);
    height: calc(100svh - $topbar-height);
  }
}

.map-zoom {
  position: absolute;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.698);
  padding: 5px 10px;
  border-radius: 4px;
  bottom: 10px;
  left: 10px;
  width: 110px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  opacity: 0;
  transition: all 0.15s ease-in-out;
  color: rgb(16, 16, 16);

  &.displayed {
    opacity: 1;
  }
}

.map-popup {
  max-width: 350px;
  user-select: text;
  cursor: default;
  
  .mapboxgl-popup-content {
    font-size: 14px;
    max-height: 450px;
    overflow: auto;

    > div {
      max-height: 170px;
      overflow: auto;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}