@import 'assets/css/constants.scss';

#projects {
  padding: 33px 24px 33px 66px;
  transition: all 0.15s ease-in-out;

  .actions {
    display: flex;
    margin-bottom: 24px;
    gap: 24px;

    .simple-dropdown {
      min-width: 220px;
    }
  }

  .wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, 300px);
    gap: 25.5px;
  }

  &.side-menu-opened {
    padding-left: calc($side-menu-width + 66px);
  }

  @media screen and (max-width: 768px) {
    &.side-menu-opened {
      padding-left: calc($side-menu-width-mobile + 20px);
    }
  }
}