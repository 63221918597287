.input-wrapper {
  display: flex;
  flex-direction: column;

  label {
    font-size: 12px;
    color: #161616;
    font-weight: bold;
    margin-bottom: 4px;
  }

  .input {
    border-radius: 6px;
    border: 1px solid #d5d5d5;
    padding: 10px 12px;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    color: #797979;
  }
}