@import 'assets/css/constants.scss';

.table {
  position: absolute;
  z-index: 3;
  top: $topbar-height + $project-header-height;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - $topbar-height - $project-header-height);
  height: calc(100svh - $topbar-height - $project-header-height);
  background-color: white;
  overflow-y: auto;

  > div.actions {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px 20px;

    .input-wrapper.select {
      margin: 0 auto;
    }

    svg {
      width: 40px;
      cursor: pointer;
      transition: all 0.15s ease-in-out;

      &:hover {
        transform: scale(1.1);
      }

      &.disabled {
        opacity: 0.5;
        pointer-events: none;

        &:hover {
          transform: none;
        }
      }
    }
  }
}