@import 'assets/css/constants.scss';

@mixin dropdown-modifier($color, $borderWidth, $justify, $padding, $class) {
  &.dropdown--#{$class} {    
    .dropdown-wrapper {
      display: flex;
      align-items: center;
      justify-content: $justify;
      gap: 12px;
      width: 100%;
      height: 47px;
      border-radius: 6px;
      border: $borderWidth solid $color-secondary;
      padding: $padding;
      
      > span {
        color: $color;
        font-size: 16px;
        font-weight: 400;
        line-height: 16px;
      }

      button > svg {
        color: $color
      }
    }
  }
};

.simple-dropdown {
  cursor: pointer;

  .drop-down-button {
    background: none;
    border: none;
    color: #C6C6C6;
    cursor: pointer;
  }

  @include dropdown-modifier(#969696, 1px, space-between, 0 21px, chevron);
  @include dropdown-modifier(#161616, 0, flex-end, 0, chevron-light);

  &.disabled {
    pointer-events: none;
    opacity: 0.3;
  }
}



 