@import 'assets/css/constants.scss';

#import{
  min-height: calc(100vh - $topbar-height);
  min-height: calc(100svh - $topbar-height);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $color-secondary;
  transition: all 0.15s ease-in-out;
  gap: 10px;
  padding: 32px;

  .info {
    padding: 20px;
    width: 100%;
    height: 219px;
    gap: 25px;
    background-color: white;

    p {
      color: #161616;
      font-size: 22px;
      font-weight: 400;
      line-height: 36px;
      text-align: center;
    }
  }

  /* Upload.tsx */
  .upload {
    width: 100%;
    max-width: 396px;
    min-width: 200px;
    border: 1px dashed $color-main;
    cursor: pointer;
    transition: all 0.15s ease-in-out;
    background-color: white;
    border-radius: 6px;
    padding: 48px;
    gap: 37px;
    margin: auto;

    > p, > span {
      text-align: center;
      font-family: Avenir;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px
    }

    > p {
      color: rgba(24, 24, 24, 0.70);

      > span {
        color: $color-main;
      }
    }

    &:hover {
      transform: scale(1.05);
      box-shadow: 0px 0px 10px #671cca4e;
    }
  }
  
  /* Loading.tsx */
  .loading {
    width: 100%;
    max-width: 525px;
    min-width: 200px;
    background-color: white;
    transition: all 0.15s ease-in-out;
    border-radius: 6px;
    margin: auto;
    padding: 24px 34px;
    gap: 44px;

    h2 {
      font-size: 25px;
      line-height: 31px;
      margin-bottom: 12px;
    }

    p {
      font-size: 18px;
      line-height: 24px;
    }
  }

  /* Error.tsx / Success.tsx */
  .error, .success {
    width: 100%;
    max-width: 375px;
    min-width: 200px;
    background-color: white;
    transition: all 0.15s ease-in-out;
    border-radius: 6px;
    margin: auto;
    padding: 25px;
    gap: 24px;

    > div {
      display: flex;
      align-items: center;
      gap: 20px;

      > svg {
        width: 40px;
        height: 40px;
      }
    }

    p.result {
      width: 100%;
      background: $color-secondary;
      padding: 12px;
    }
  }

  /* Rejected.tsx */
  .rejected {
    width: 100%;
    max-width: 614px;
    min-width: 200px;
    background-color: white;
    transition: all 0.15s ease-in-out;
    border-radius: 6px;
    margin: auto;
    padding: 32px;

    .accordion {
      h3 {
        border-bottom: 1px solid #E2E7EF;
        border-left: 1px solid #E2E7EF;
        border-right: 1px solid #E2E7EF;
        padding: 21px 16px;
        background: #F4F3F7;
        font-size: 14px;
        line-height: 16px;
      }

      .wrapper {
        gap: 0;

        .line {
          align-items: flex-start;
          gap: 5px;
          padding: 16px;
          border-top: 1px solid #E2E7EF;
          border-left: 1px solid #E2E7EF;
          border-right: 1px solid #E2E7EF;

          &:first-of-type {
            border-top: none;
          }

          &:last-of-type {
            border-bottom: 1px solid #E2E7EF;
          }

          .data {
            width: 100%;
            font-size: 14px;
            word-break: break-all;
          }
        }
      }

      &:first-of-type h3 {
        border-top: 1px solid #E2E7EF;
      }

      &.expanded h3 {
        margin-bottom: 0;
      }
    }

    button:first-of-type {
      margin-top: 32px;
      margin-bottom: 24px;
    }
  }

  &.side-menu-opened {
    padding-left: calc($side-menu-width);
  }

  @media screen and (max-width: 768px) {
    &.side-menu-opened {
        padding-left: calc($side-menu-width-mobile);
    }
  }

  @media screen and (max-width: 605px) {
    .info {
      p {
        font-size: 18px;
        line-height: 30px;
      }
    }

    .action {
      transform: scale(0.75);
    }
  }

  @media screen and (max-width: 460px) {
    .info {
      height: unset;
      
      p {
        font-size: 16px;
        line-height: 28px;
      }

      button {
        font-size: 12px;
      }
    }
  }
}