@import 'assets/css/constants.scss';

.side-menu {
  display: flex;
  flex-direction: column;
  padding-top: 28px;
  height: calc(100vh - $topbar-height);
  height: calc(100svh - $topbar-height);
  width: $side-menu-width;
  position: fixed;
  top: $topbar-height;
  left: 0;
  background-color: #161616;
  transform: translateX(calc($side-menu-width * -1));
  transition: all 0.15s ease-in-out;
  z-index: 999;

  &.opened {
    transform: translateX(0);
  }

  a, button {
    all: unset;
    height: 56px;
    display: flex;
    align-items: center;
    padding: 0 39px;
    gap: 12px;
    color: white;
    cursor: pointer;
    font-size: 17px;
    font-weight: 400;
    line-height: 17px;
    margin: 0 10px;

    * {
      fill: white;
    }

    &:hover {
      text-decoration: underline;
    }

    &.active {
      background-color: $color-secondary;
      color: $color-main;

      * {
        fill:  $color-main;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .side-menu {
    width: $side-menu-width-mobile;
    transform: translateX(calc($side-menu-width-mobile * -1));

    a, button {
      padding: 0 10px;
    }
  }
}