@import 'assets/css/constants.scss';

.editor {
  flex-shrink: 0;
  display: flex;
  background-color: #424242;
  color: white;

  .wrapper {
    width: 370px;
    height: calc(100vh - $topbar-height - $project-header-height);
    height: calc(100svh - $topbar-height - $project-header-height);

    > section {
      height: 100% !important;
    }
    
    .monaco-editor {
      padding-top: 5px;
    }
  
    .minimap {
      display: none;
      width: 0;
    }
  
    .codicon-folding-collapsed:before {
      content: "+" !important;
    }
    
    .codicon-folding-expanded:before {
      content: "-" !important;
    }

    .scrollbar.vertical, canvas.decorationsOverviewRuler {
      width: 7px !important;
    }

    .errors {
      height: 0;
      display: flex;
      padding: 0;
      flex-direction: column;
      gap: 10px;
      word-wrap: break-word;
      background-color: #1e1e1e;
      color: #ff7575;
      font-weight: 400;
      overflow: hidden;
  
      p {
        font-family: "Lucida Console", Monaco, monospace !important;
        font-size: 10px;
        border-bottom: 1px solid rgb(76 55 55);
        padding-bottom: 10px;
      }
  
      &::-webkit-scrollbar {
        display: none;
      }
    }

    &.errors-displayed {
      > section {
        height: 80% !important;
      }
  
      .errors {
        height: 20%;
        padding: 20px 10px;
        overflow-y: auto;
  
        > p:first-of-type {
          border-top: 1px solid rgb(76, 55, 55);
          padding-top: 10px;
        }
      }
    }
  }
}